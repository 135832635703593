import {matrixStyle, wellStyle, wellFlashClass, rowColumnToWellLabel} from './templateHelpers.js';

export default function kineticsIndexedTemplate(data, containerIndex, matrixIndex, matrixData) {
    return `\
<div class="matrix xy-kinetics-indexed" ${matrixStyle(matrixData)}>
    ${matrixData.wells.map((well, wellIndex) => `
    <div class="well-label ${wellFlashClass(data, wellIndex)}" ${wellStyle(data, well)}><span>${rowColumnToWellLabel(well.row, well.column)}</span></div>
    ${well.dataPoints.map(dataPoint => `
    <div class="data-point ${wellFlashClass(data, wellIndex)}" ${dataPoint.value === '-' ? `style="background-color: white; color: transparent"` : wellStyle(data, well)}><span>${dataPoint.value}</span></div>`).join('')}
    `).join('')}
</div>
`
}
