export default class extends EventTarget {
    constructor(pendingDelay = 1000, validateDelay = 2000, removeStatusDelay = 1000, exitEditModeDelay = 1000) {
        super();
        this._pendingDelay = pendingDelay;
        this._validateDelay = validateDelay;
        this._removeStatusDelay = removeStatusDelay;
        this._exitEditModeDelay = exitEditModeDelay;
        this._monitoring = false;
        this._delayID = null;
        this._status = this.STATUS_STOPPED;
        this._hasValidated = false;
        this._immediateValidation = false;
        this._errorMessage = '';
    }

    EVENT_STATUS_CHANGE = 'status-change';
    STATUS_STOPPED = 'stopped';
    STATUS_IDLE = 'idle';
    STATUS_ACTIVITY = 'activity';
    STATUS_PENDING_VALIDATION = 'pending-validation';
    STATUS_VALIDATING = 'validating';
    STATUS_VALID = 'valid';
    STATUS_INVALID = 'invalid';

    EVENT_VALIDATE = 'validate';
    EVENT_EXIT_EDIT_MODE = 'exit-edit-mode';

    _setStatus(status) {
        if (this._monitoring && status !== this._status) {
            if (this._delayID !== null) {
                clearTimeout(this._delayID);
                this._delayID = null;
            }
            switch (status) {
                case this.STATUS_STOPPED:
                    break;
                case this.STATUS_IDLE:
                    if (this._hasValidated) {
                        this._delayID = setTimeout(() => {
                            this._delayID = null;
                            this._dispatchNewEvent(this.EVENT_EXIT_EDIT_MODE);
                        }, this._exitEditModeDelay);
                    }
                    break;
                case this.STATUS_ACTIVITY:
                    this._hasValidated = false;
                    this._delayID = setTimeout(() => {
                        this._delayID = null;
                        this._setStatus(this.STATUS_PENDING_VALIDATION);
                    }, this._pendingDelay);
                    break;
                case this.STATUS_PENDING_VALIDATION:
                    this._delayID = setTimeout(() => {
                        this._delayID = null;
                        this._setStatus(this.STATUS_VALIDATING);
                    }, this._validateDelay);
                    break;
                case this.STATUS_VALIDATING:
                    this._dispatchNewEvent(this.EVENT_VALIDATE);
                    break;
                case this.STATUS_VALID:
                    this._hasValidated = true;
                    if (this._immediateValidation) {
                        this._immediateValidation = false;
                        this._dispatchNewEvent(this.EVENT_EXIT_EDIT_MODE);
                    } else {
                        this._delayID = setTimeout(() => {
                            this._delayID = null;
                            this._setStatus(this.STATUS_IDLE);
                        }, this._removeStatusDelay);
                    }
                    break;
                case this.STATUS_INVALID:
                    break;
            }
            this._status = status;
            this.dispatchEvent(new CustomEvent(this.EVENT_STATUS_CHANGE, {
                detail: {
                    status,
                    errorMessage: this._errorMessage,
                },
            }));
        }
    }

    _dispatchNewEvent(type) {
        this.dispatchEvent(new CustomEvent(type));
    }

    get status() {
        return this._status;
    }

    start() {
        if (!this._monitoring) {
            this._hasValidated = false;
            this._monitoring = true;
            this._setStatus(this.STATUS_IDLE);
        }
    }

    stop() {
        if (this._monitoring) {
            this._setStatus(this.STATUS_STOPPED);
            this._monitoring = false;
        }
    }

    validateNow() {
        this._immediateValidation = true;
        this._setStatus(this.STATUS_VALIDATING);
    }

    registerActivity() {
        this._setStatus(this.STATUS_ACTIVITY);
    }

    setValidationResult(valid, errorMessage = '') {
        this._errorMessage = errorMessage;
        this._setStatus(valid ? this.STATUS_VALID : this.STATUS_INVALID);
    }
}
