
export default class FlashTimer {
    static _startTimer() {
        if (this._timerId === undefined) {
            this._state = false;
            this._timerId = setInterval(() => this._toggleState(), 500);
        }
    }

    static _stopTimer() {
        clearInterval(this._timerId);
        delete this._timerId;
    }

    static _toggleState() {
        this._state = !this._state;
        this._subscribers.forEach(handler => handler(this._state));
    }

    static subscribe(handler) {
        this._subscribers || (this._subscribers = []);
        if (! this._subscribers.includes(handler)) {
            this._subscribers.push(handler);
        }
        this._startTimer();
    }

    static unSubscribe(handler) {
        this._subscribers = this._subscribers.filter(item => item !== handler);
        if (this._subscribers.length === 0) {
            delete this._subscribers;
            this._stopTimer();
        }
    }

    static get state() {
        return this._state;
    }
}
