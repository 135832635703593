import {wellStyle, wellFlashClass} from './templateHelpers.js';

export default function endPointTemplate(data, containerIndex, matrixIndex, matrixData) {
    return `\
<div class="matrix endpoint">
    ${matrixData.wells.map((well, wellIndex) => {
        const value = well.dataPoints[0].value;
        const unknown = value === '-';
        return `
    <div class="well ${wellFlashClass(data, wellIndex)}" ${wellStyle(data, well, unknown)}><span>${value}</span></div>
    `;
    }).join('')}
</div>
`
}
