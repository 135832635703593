import endPointTemplate from './endPointTemplate.js';
import kineticsTemplate from './kineticsTemplate.js';
import kineticsIndexedTemplate from './kineticsIndexedTemplate.js';
import spectralTemplate from './spectralTemplate.js';
import wellScanTemplate from './wellScanTemplate.js';

export function rowColumnToWellLabel(row, column) {
    return String.fromCharCode('A'.charCodeAt(0) + row) + String(column + 1);
}

export function wellFlashClass(data, wellIndex) {
    return data.flashEnabled && data.flashWellPositions.includes(wellIndex + 1) ? 'flashing' : '';
}

export function wellStyle(data, well, unknown = false) {
    if (unknown) {
        return `style="background-color: transparent; color: transparent !important"`;
    } else {
        const colour = data.colours[well.row * data.spec.containerWidth + well.column];
        return `style="background-color: ${colour}"`;
    }
}

export function matrixStyle(matrixData) {
    let maxDataPoints = 0;
    matrixData.wells.forEach(well => {
        maxDataPoints = Math.max(maxDataPoints, well.dataPoints.length);
    });
    return `style="grid-template-columns: repeat(${maxDataPoints + 1}, fit-content(100%))"`;
}

export function getMatrixTemplate(type) {
    return {
        'endpoint': endPointTemplate,
        'xy-kinetics': kineticsTemplate,
        'xy-kinetics-indexed': kineticsIndexedTemplate,
        'xy-spectral': spectralTemplate,
        'xyz-well-scan': wellScanTemplate,
    }[type.toLowerCase()];
}
