import MyassaysPad from './MyassaysPad';

customElements.define('myassays-pad', MyassaysPad);

if (window.MyAssaysPad !== undefined) {
    if (!(window.MyAssaysPad instanceof Object)) {
        throw new Error('Could not create "window.MyAssaysPad" namespace.');
    }
} else {
    window.MyAssaysPad = {};
}
window.MyAssaysPad = {
    ...window.MyAssaysPad,
    getPadById: function (padId) {
        const padElem = document.getElementById(padId);
        if (!padElem) {
            throw `An instance of MyassaysPad with the id "${padId}" could not be found.`;
        } else if (! padElem instanceof MyassaysPad) {
            throw `The element with the id "${padId}" is not a MyassaysPad.`;
        } else {
            return padElem;
        }
    },
    getValue: function (padId) {
        return this.getPadById(padId).value;
    },
    setValue: function (padId, newValue) {
        this.getPadById(padId).value = newValue;
    },
    setColours: function (padId, newValue) {
        this.getPadById(padId).colours = newValue;
    },
    setFlashWellPositions: function (padId, newValue) {
        this.getPadById(padId).setAttribute('flash-well-positions', newValue);
    },
    toggleFlashEnabled: function (padId, forceValue = undefined) {
        const pad = this.getPadById(padId);
        const enabled = String(pad.getAttribute('flash-enabled')).toLowerCase() !== 'false';
        pad.setAttribute('flash-enabled', (forceValue === undefined ? !enabled : forceValue) ? 'true' : 'false');
    },
}
