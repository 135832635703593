import { getMatrixTemplate } from './templateHelpers.js';

export default function gridTemplate (data) {
    const { model, spec } = data;
    return `\
<style>
    #grid {
        ${Object.keys(data.fontStyles).map(key => {
        let value = data.fontStyles[key];
        return key + ':' + value;
    }).join(';')};
    }
    #grid .heading {
        padding: 0 ${data.cellPadding};
    }
    #grid .matrix.endpoint {
        grid-template-columns: repeat(${spec.containerWidth}, fit-content(100%));
    }
    #grid .matrix > * {
        padding: 0 ${data.cellPadding};
    }
    
    .well, .well-label, .data-point {
        color: black;
    }
    
    .flashing {
        transition: background-color 1000ms;
    }
    
    #grid:not(.flash-on) .flashing {
        transition: background-color 300ms;
    }    
 
    #grid.flash-on .flashing {
        color: ${data.flashColour} !important;
        background-color: ${data.flashBackgroundColour} !important;
    }
    
</style>
${model.containers.map((container, containerIndex) => `\
${spec.multipleContainers ? `<div class="heading"><span>${container.label}</span></div>` : ''}
<div class="container">
    ${container.matrices.map((matrix, matrixIndex) => {
        const matrixTypeId = spec.matrices[matrixIndex];
        const matrixData = data.model.containers[containerIndex].matrices[matrixIndex];
        const matrixTemplate = getMatrixTemplate(matrixTypeId);
        const label = matrix.label;
        return matrixTemplate ? `
        ${container.matrices.length > 1 ? `\
        <div class="heading"><span>${label}</span></div>
        ` : ''}
        ${matrixTemplate(data, containerIndex, matrixIndex, matrixData)}
        ` : '';
    }).join('')}
</div>`).join('')}`;
}
