import Model from 'myassays-global/Model';

function splitLine(raw) {
    if (raw.includes('\t')) {
        return raw.trim().split(/\t/).map(item => item.trim());
    } else {
        return raw.trim().split(/ +/);
    }
}

class WellScanDataPoint extends Model {
    static defaults = {
        value: '',
    }
}

class KineticsDataPoint extends Model {
    static defaults = {
        time: '',
        value: '',
    }
}

class KineticsIndexedDataPoint extends Model {
    static defaults = {
        value: '',
    }
}

class SpectralDataPoint extends Model {
    static defaults = {
        wavelength: '',
        value: '',
    }
}

class EndPointDataPoint extends Model {
    static defaults = {
        value: '',
    }
}

class Well extends Model {
    constructor(rawText) {
        super();
        this.fromRaw(rawText);
    }

    fromRaw(raw) {

    }

    toRaw() {
        return '';
    }
    get wellLabelToRowColumn() {
        return Well._wellLabelToRowColumn;
    }
    get rowColumnToWellLabel() {
        return Well._rowColumnToWellLabel;
    }
    static defaults = {
        row: 0,
        column: 0,
        dataPoints: [],
    }
    static _wellLabelToRowColumn(wellLabel) {
        const row = wellLabel.charCodeAt(0) - 'A'.charCodeAt(0);
        const column = Number(wellLabel.slice(1)) - 1;
        return {row, column};
    }
    static _rowColumnToWellLabel(row, column) {
        return String.fromCharCode('A'.charCodeAt(0) + row) + String(column + 1);
    }
}

class WellScanWell extends Well {
    fromRaw(raw) {
        // A1 6.738 12.862 21.816 27.748
        const parts = splitLine(raw);
        const { row, column } = this.wellLabelToRowColumn(parts.shift());
        const dataPoints = parts.map(part => {
            return new WellScanDataPoint({value: part});
        });
        this.set({row, column, dataPoints});
    }

    toRaw() {
        const { row, column, dataPoints } = this.attributes;
        const parts = [this.rowColumnToWellLabel(row, column)];
        dataPoints.forEach(dataPoint => {
            parts.push(dataPoint.value);
        });

        return parts.join(' ');
    }
}

class KineticsWell extends Well {
    fromRaw(raw) {
        // A1 0.000;7.769 0.100;17.496 0.200;23.541 0.300;33.412 0.400;39.010 0.500;52.017 0.600;59.519 0.700;60.001 0.800;72.080 0.900;84.380
        const parts = splitLine(raw);
        const { row, column } = this.wellLabelToRowColumn(parts.shift());
        const dataPoints = parts.map(part => {
            const [ time, value ] = part.split(';');
            return new KineticsDataPoint({time, value});
        });
        this.set({row, column, dataPoints});
    }

    toRaw() {
        const { row, column, dataPoints } = this.attributes;
        const parts = [this.rowColumnToWellLabel(row, column)];
        dataPoints.forEach(dataPoint => {
            const { time, value } = dataPoint;
            parts.push(`${time};${value}`);
        })
        return parts.join(' ');
    }
}

class KineticsIndexedWell extends Well {
    fromRaw(raw) {
        // A1 1.300 2.274 3.869 4.827 5.704 6.985 9.071 8.979 11.448 11.410
        const parts = splitLine(raw);
        const { row, column } = this.wellLabelToRowColumn(parts.shift());
        const dataPoints = parts.map(part => {
            return new KineticsIndexedDataPoint({value: part});
        });
        this.set({row, column, dataPoints});
    }

    toRaw() {
        const { row, column, dataPoints } = this.attributes;
        const parts = [this.rowColumnToWellLabel(row, column)];
        dataPoints.forEach(dataPoint => {
            const { value } = dataPoint;
            parts.push(value);
        })
        return parts.join(' ');
    }
}

class SpectralWell extends Well {
    fromRaw(raw) {
        // A1 300.000;0.061 350.000;0.187 400.000;0.418 450.000;0.676 500.000;0.794 550.000;0.676 600.000;0.418 650.000;0.187 700.000;0.061
        const parts = splitLine(raw);
        const { row, column } = this.wellLabelToRowColumn(parts.shift());
        const dataPoints = parts.map(part => {
            const [ wavelength, value ] = part.split(';');
            return new SpectralDataPoint({wavelength, value});
        });
        this.set({row, column, dataPoints});
    }

    toRaw() {
        const { row, column, dataPoints } = this.attributes;
        const parts = [this.rowColumnToWellLabel(row, column)];
        dataPoints.forEach(dataPoint => {
            const { wavelength, value } = dataPoint;
            parts.push(`${wavelength};${value}`);
        })
        return parts.join(' ');
    }
}

class EndPointWell extends Well {
    constructor(rawText, row, column) {
        super(rawText);
        this.set({row, column});
    }

    fromRaw(raw) {
        this.set({row: this.row, dataPoints: [new EndPointDataPoint({value: raw})]});
    }

    toRaw() {
        return this.get('dataPoints').map(dataPoint => dataPoint.value).join('');
    }
}

class Matrix extends Model {
    constructor(matrixTypeId, matrixData, rawWellData, dataSpec) {
        super(matrixData);
        this.matrixTypeId = matrixTypeId;
        this.dataSpec = dataSpec;
        this.fromRaw(rawWellData);
    }

    get _WellClass() {
        switch (this.matrixTypeId.toLowerCase()) {
            case 'xy-kinetics': return KineticsWell;
            case 'xy-kinetics-indexed': return KineticsIndexedWell;
            case 'xy-spectral': return SpectralWell;
            case 'xyz-well-scan': return WellScanWell;
            default: return EndPointWell;
        }
    }

    static defaults = {
        wells: [],
        label: '',
        indicesLabel: '',
    }

    fromRaw(raw) {
        let rawWells;
        if (this.matrixTypeId === 'endpoint') {
            rawWells = raw.split(/[\n\t ]+/);

            while (rawWells.length < this.dataSpec.containerWidth * this.dataSpec.containerHeight) {
                rawWells.push('-');
            }
            this.set({wells: rawWells.map((rawWell, i) => {
                const row = Math.floor(i / this.dataSpec.containerWidth);
                const column = i - row * this.dataSpec.containerWidth;
                return new EndPointWell(rawWell, row, column);
            })});
        } else {
            rawWells = raw.split(/ ?\n ?/);
            this.set({wells: rawWells.map(rawWell => new this._WellClass(rawWell))});
        }
    }

    toRaw() {
        if (this.matrixTypeId === 'endpoint') {
            const re = new RegExp(`((?:[^ ]* ){${this.dataSpec.containerWidth - 1}}[^ ]*) `, 'g');
            const colWidths = Array(this.dataSpec.containerWidth).fill(0);
            let raw = this.getExact('wells').map(well => {
                const rawWell = well.toRaw();
                const column = well.get('column');
                colWidths[column] = Math.max(colWidths[column], rawWell.length);
                return rawWell;
            }).join(' ').replace(re, '$1\n').trim();

            let col = 0;
            return raw.replace(/[^ \n]+/mg, match => {
                const width = colWidths[col];
                col++;
                if (col === this.dataSpec.containerWidth) {
                    col = 0;
                }
                return match.padEnd(width, ' ');
            });
        } else {
            let raw = '';
            const colWidths = [];
            raw += this.getExact('wells').map(well => {
                const rawWell = well.toRaw()
                rawWell.split(/ +/).forEach((value, col) => {
                    const width = colWidths[col] || 0;
                    colWidths[col] = Math.max(width, value.length);
                });
                return rawWell;
            }).join('\n');
            let col = 0;
            raw = raw.replace(/[^ \n]+/mg, match => {
                if (/^[A-Z][0-9]{1,2}$/.test(match)) {
                    col = 0;
                }
                const width = colWidths[col];
                col++;
                return match.padEnd(width, ' ');
            });
            if (this.matrixTypeId === 'xyz-well-scan') {
                raw = `${this.get('indicesLabel')}\n` + raw;
            }
            return raw;
        }
    }
}

class Container extends Model {
    static defaults = {
        label: '',
        matrices: [],
    }

    toRaw() {
        const matrices = this.getExact('matrices');
        const showHeadings = matrices.length > 1;

        return matrices.map((matrix, i) => {
            let label = '';
            if (showHeadings) {
                label = matrix.get('label');
                if (!label) {
                    label = `Raw${i + 1}`;
                }
            }
            return `${label ? `${label}\n` : ''}${matrix.toRaw()}`;
        }).join('\n');
    }
}

export default class DataModel extends Model {
    constructor(rawText, dataSpec) {
        super();
        this.dataSpec = dataSpec;
        this.fromRaw(rawText);
    }

    static defaults = {
        containers: [],
    }

    toRaw() {
        const containers = this.getExact('containers');
        return containers.map((container, i) => {
            let label = '';
            if (this.dataSpec.multipleContainers) {
                label = container.get('label');
                if (!label) {
                    label = (this.dataSpec.containerType === 'plex' ? 'Analyte' : 'Plate') + ` ${i + 1}`;
                }
            }
            return `${label ? `${label}\n` : ''}${container.toRaw()}`;
        }).join('\n');
    }

    fromRaw(raw) {
        // consume the raw file line by line
        const whitespacePattern = '[ \\n\\t]+';

        const numberPattern = '-?[0-9]+([.,][0-9]+e-?[0-9]{1,2}|[.,][0-9]+|[.,]?e-?[0-9]{1,2})?';
        const wellLabelPattern = '[A-Z][1-9][0-9]?';
        const xyValuePattern = `${numberPattern};${numberPattern}`;

        const getRE = matrixTypeId => {
            switch (matrixTypeId) {
                case 'endpoint': return new RegExp(`^((${numberPattern}|-)${whitespacePattern})*(${numberPattern}|-)$`, 'i');
                case 'xy-kinetics-indexed':
                case 'xyz-well-scan': return new RegExp(`^${wellLabelPattern}(${whitespacePattern}(${numberPattern}|-))*$`, 'i');
                case 'xy-kinetics':
                case 'xy-spectral': return new RegExp(`^${wellLabelPattern}(${whitespacePattern}${xyValuePattern})*$`, 'i');
                default: throw new Error(`matrix type "${matrixTypeId}" not recognised.`);
            }
        }

        const lines = raw.split('\n').map(line => line.trim()).filter(line => line !== '');

        const containers = [];
        while (lines.length) {
            const containerData = {};
            if (this.dataSpec.multipleContainers) {
                containerData.label = lines.shift();
            }
            containerData.matrices = [];
            this.dataSpec.matrices.forEach(matrixTypeId => {
                const re = getRE(matrixTypeId);
                const matrixData = {};
                if (this.dataSpec.matrices.length > 1) {
                    matrixData.label = lines.shift();
                }
                if (matrixTypeId === 'xyz-well-scan') {
                    matrixData.indicesLabel = lines.shift();
                }
                const rawWellData = [];
                while (lines.length) {
                    const line = lines.shift();

                    if (re.test(line)) {
                        rawWellData.push(line);
                    } else {
                        const previousLine = rawWellData.pop();
                        if (re.test(previousLine + ' ' + line)) {
                            rawWellData.push(previousLine + ' ' + line);
                        } else {
                            rawWellData.push(previousLine);
                            lines.unshift(line);
                            break;
                        }
                    }
                }
                if (rawWellData.length > 0) {
                    containerData.matrices.push(new Matrix(matrixTypeId, matrixData, rawWellData.join('\n'), this.dataSpec));
                }
            });
            if (containerData.matrices.length === this.dataSpec.matrices.length) {
                containers.push(new Container(containerData));
            }
            if (!this.dataSpec.multipleContainers) break;
        }
        this.set({containers});
    }
}
