
import DataModel from './model/DataModel.js';
import styles from './MyassaysPad.css?raw';
import padStatusTemplate from './templates/padStatusTemplate.js';
import gridTemplate from './templates/gridTemplate.js';
import PropTypes from 'myassays-global/PropTypes';
import FlashTimer from 'myassays-global/FlashTimer';
import ActivityMonitor from './ActivityMonitor.js';
import {MyassaysComponent} from 'myassays-global';

const MODE_EDIT = 'edit';
const MODE_DISPLAY = 'display';

function template (data) {
    return `\
<style>
    ${styles}
    
    #pad {
        padding: 0 ${data.cellPadding};
        ${Object.keys(data.fontStyles).map(key => key + ':' + data.fontStyles[key]).join(';')};
    }
    #pad.hide-text {
        color: transparent !important;
        background-color: transparent !important;
    }
</style>
<div id="grid-container">
    <div id="grid" class="${FlashTimer.state ? 'flash-on' : ''}" part="grid"> </div>
</div>
<div id="pad-status"> </div>
<textarea id="pad" class="${data.mode === MODE_DISPLAY ? 'hide-text' : ''}" part="pad ${data.mode === MODE_DISPLAY ? 'hide-text' : ''}"> </textarea>`;
}

export default class MyassaysPad extends MyassaysComponent {
    static get propTypes() {
        return {
            id: PropTypes.string,
            style: PropTypes.string,
            dataMode: PropTypes.string.lookup([MODE_DISPLAY, MODE_EDIT]).default(MODE_DISPLAY).observed,
            dataPmcSpec: PropTypes.json.required.observed,
            dataValue: PropTypes.string.required.observed,
            dataColours: PropTypes.string.required.observed,
            autoValidationDelays: PropTypes.string.regExp(/^\d+ *, *\d+ *, *\d+ *, *\d+$/),
            padStyle: PropTypes.style,
            numRowsMin: PropTypes.number.default(10).observed,
            numRowsMax: PropTypes.number.default(-1).observed,
            dataValidation: PropTypes.string.observed,
            flashEnabled: PropTypes.bool.default(true).observed,
            flashWellPositions: PropTypes.string.regExp(/^\d*( *, *\d*)*$/).default('').observed,
            flashColour: PropTypes.string.default('black'),
            flashBackgroundColour: PropTypes.string.default('white'),
        }
    }

    constructor() {
        super();

        this.shadow = this.attachShadow({mode: 'open'});
    }

    onFlashStateChange = state => {
        const grid = this.shadow.getElementById('grid');
        if (grid) {
            grid.classList.toggle('flash-on', state);
        }
    }

    onActivityStatusChange(evt) {
        const { status, errorMessage } = evt.detail;
        const pad = this.shadow.getElementById('pad');
        const am = this.activityMonitor;
        let newPadStatus = 'none';
        let error = undefined;
        switch (status) {
            case am.STATUS_STOPPED:
            case am.STATUS_IDLE:
            case am.STATUS_ACTIVITY:
                break;
            case am.STATUS_PENDING_VALIDATION:
            case am.STATUS_VALIDATING:
                newPadStatus = 'validating';
                break;
            case am.STATUS_VALID:
                if (this._props.dataMode === MODE_EDIT) newPadStatus = 'ok';
                this.dispatchEvent(new CustomEvent('padchangevalidated', {
                    detail: pad.value,
                    bubbles: true,
                }));
                break;
            case am.STATUS_INVALID:
                newPadStatus = 'failed';
                error = errorMessage;
                pad.focus();
                break;
        }
        this.updatePadStatus(newPadStatus, error);
    }

    onActivityValidate() {
        this.validate().then(error => {
            this.activityMonitor.setValidationResult(!error, error);
        });
    }

    onActivityExitEditMode() {
        const pad = this.shadow.getElementById('pad');
        pad.blur();
        this._props.dataMode = MODE_DISPLAY;
        this.configure();
    }

    static get observedAttributes() {
        return PropTypes.getObserved(this.propTypes);
    }

    connectedCallback() {
        this._props = PropTypes.attributesToProps(this);

        if (this._props.autoValidationDelays) {
            const [ pendingDelay, validateDelay, removeStatusDelay, exitEditModeDelay ] = this._props.autoValidationDelays.split(/ *, */).map(item => Number(item));
            this.activityMonitor = new ActivityMonitor(pendingDelay, validateDelay, removeStatusDelay, exitEditModeDelay);
        } else {
            this.activityMonitor = new ActivityMonitor();
        }
        const am = this.activityMonitor;
        this.validationId = 0;

        am.addEventListener(am.EVENT_STATUS_CHANGE, evt => this.onActivityStatusChange(evt));
        am.addEventListener(am.EVENT_VALIDATE, () => this.onActivityValidate());
        am.addEventListener(am.EVENT_EXIT_EDIT_MODE, () => this.onActivityExitEditMode());

        this.rawData = this._props.dataValue;
        this._colours = this._props.dataColours.trim().split(/[ \n]+/);

        this.parsePadStyles();
        this.testCellPadding(this.fontStyleRules).then(padding => {
            this.leftRightCellPadding = padding;
            this.shadow.innerHTML = template({
                cellPadding: this.leftRightCellPadding,
                fontStyles: this.fontStyleRules,
            });

            this.configure();
            this.resizeAndScroll();
            const pad = this.shadow.getElementById('pad');
            const grid = this.shadow.getElementById('grid');
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizeAndScroll();
            });

            this.resizeObserver.observe(pad);
            pad.addEventListener('scroll', () => this.resizeAndScroll());
            pad.addEventListener('input', this.onInput);

            // NOTE: In FireFox, the textarea triggers a selectionchange event. In all other
            // browsers, the document triggers it.
            pad.addEventListener('mousedown', this.onPadMouseDown)
            document.addEventListener('selectionchange', this.onSelectionChange);
            pad.addEventListener('selectionchange', this.onSelectionChange);

            this._hasBeenRendered = true;
        });

        FlashTimer.subscribe(this.onFlashStateChange);
    }

    disconnectedCallback() {
        FlashTimer.unSubscribe(this.onFlashStateChange);
    }

    resizeAndScroll() {
        const pad = this.shadow.getElementById('pad');
        const gridContainer = this.shadow.getElementById('grid-container');
        const grid = this.shadow.getElementById('grid');

        let numRows = pad.value.split('\n').length;
        const { numRowsMin, numRowsMax } = this._props;

        if (numRowsMin !== -1) {
            numRows = Math.max(numRows, numRowsMin);
        }

        if (numRowsMax !== -1) {
            numRows = Math.min(numRows, numRowsMax);
        }

        //const { lineHeight } = getComputedStyle(pad);
        //pad.style.height = (parseFloat(lineHeight) * numRows + pad.offsetHeight - pad.clientHeight) + 'px';


        const { lineHeight, borderTopWidth, borderBottomWidth } = getComputedStyle(pad);
        const topBottomBorderWidth = parseFloat(borderTopWidth) + parseFloat(borderBottomWidth);
        const padHeight = (parseFloat(lineHeight) * numRows + pad.offsetHeight - pad.clientHeight) + topBottomBorderWidth;
        pad.style.height = padHeight + 'px';

        const { width, height } = pad.getBoundingClientRect();
        const { scrollTop, scrollLeft } = pad;

        pad.classList.toggle('scrolling-x', pad.clientWidth < pad.scrollWidth);
        pad.classList.toggle('scrolling-y', pad.clientHeight < pad.scrollHeight);

        gridContainer.style.width = width + 'px';
        gridContainer.style.height = height + 'px';
        grid.style.top = -scrollTop + 'px';
        grid.style.left = -scrollLeft + 'px';
    }

    parsePadStyles() {
        const padStyles = this._props.padStyle;
        this.fontStyleRules = {
            'font-family': 'monospace',
            'font-size': '13px',
            'font-weight': 'normal',
            'font-style': 'normal',
            'letter-spacing': 'normal',
            'line-height': '20px',
            'color': 'inherit',
        };
        if (padStyles) {
            padStyles.trim().split(/ *; */).forEach(style => {
                const [ key, value ] = style.split(/ *: */);
                switch (key) {
                    case 'font-family':
                    case 'font-size':
                    case 'font-weight':
                    case 'font-style':
                    case 'letter-spacing':
                    case 'color':
                    case 'max-height':
                    case 'min-height':
                        this.fontStyleRules[key] = value;
                        break;
                    case 'line-height':
                        if (!/^\d+px$/.test(value)) {
                            throw new Error('Please only specify "line-height" in "px" values only');
                        }
                        this.fontStyleRules[key] = value;
                        break;
                    case '':
                        break;
                    case 'font':
                        throw new Error('Please use individual font properties in the "font-style" attribute, rather than "font".');
                    default:
                        throw new Error('Only "font-family", "font-size", "font-weight", "font-style", "letter-spacing", "line-height" and "color" properties are allowed in the "font-style" attribute.');
                }
            });
        }
    }

    attributeChangedCallback(attrName) {
        if (this._hasBeenRendered) {
            if (this._props.dataMode === MODE_DISPLAY) {
                const changedProps = PropTypes.attributesToProps(this, attrName);
                const propName = Object.keys(changedProps)[0];
                const newVal = changedProps[propName];
                this._props[propName] = newVal;

                switch (attrName) {
                    case 'data-value':
                        this.rawData = newVal;
                        break;
                    case 'data-colours':
                        this._colours = newVal.trim().split(/[ \n]+/);
                        break;
                }
                if (this.configurePendingId === undefined) {
                    this.configurePendingId = setTimeout(() => {
                        delete this.configurePendingId;
                        this.configure();
                        this.resizeAndScroll();
                    }, 1);
                }
            }
        }
    }

    testCellPadding(fontStyleRules) {
        // test the width of a character
        return new Promise(resolve => {
            this.shadow.innerHTML = `<span id="size-test" style="${Object.keys(fontStyleRules).map(key => key + ':' + fontStyleRules[key]).join(';')}">0</span>`;
            document.fonts.ready.then(() => {
                const span = this.shadow.querySelector('span');
                const test = () => {
                    const width = span.getBoundingClientRect().width;
                    if (width) {
                        // ch unit doesn't always equal '0' width, e.g. when italic.
                        // so, calculate the ch value that equates to half a character
                        span.style.paddingRight = '1ch';
                        const paddingWidth = span.getBoundingClientRect().width - width;
                        resolve((width/paddingWidth)/2 + 'ch');
                    } else {
                        setTimeout(test, 10);
                    }
                }
                test();
            });
        });
    }

    configure() {
        const grid = this.shadow.getElementById('grid');
        const pad = this.shadow.getElementById('pad');

        if (!grid || !pad) return;

        if (this._props.dataMode === MODE_DISPLAY) {
            this.dataModel = new DataModel(this.rawData, this._props.dataPmcSpec);
            this.rawData = this.dataModel.toRaw();
            const data = {
                mode: this._props.dataMode,
                spec: this._props.dataPmcSpec,
                flashEnabled: this._props.flashEnabled,
                flashWellPositions: this._props.flashWellPositions ? this._props.flashWellPositions.split(/ *, */).map(item => Number(item)) : [],
                flashColour: this._props.flashColour,
                flashBackgroundColour: this._props.flashBackgroundColour,
                colours: this._colours,
                model: this.dataModel.attributes,
                cellPadding: this.leftRightCellPadding,
                fontStyles: this.fontStyleRules,
            }
            grid.innerHTML = gridTemplate(data);
            pad.classList.toggle('hide-text', true);
            this.activityMonitor.stop();
        } else {
            grid.innerHTML = '';
            pad.classList.toggle('hide-text', false);
            this.activityMonitor.start();
        }
        pad.classList.toggle('display', this._props.dataMode === MODE_DISPLAY);
        this.updatePadStatus('none');

        pad.value = this.dataModel ? this.dataModel.toRaw() : this.rawData;
    }

    onPadMouseDown = evt => {
        const pad = evt.currentTarget;
        this._inhibitModeChange = evt.offsetY > pad.clientHeight || evt.offsetX > pad.clientWidth;
        const onMouseUp = evt => {
            this._inhibitModeChange = false;
            document.body.removeEventListener('mouseup', onMouseUp);
        }
        document.body.addEventListener('mouseup', onMouseUp);
    }

    onSelectionChange = evt => {
        if (this._inhibitModeChange) return;

        if (this._props.dataMode === MODE_DISPLAY && this === document.activeElement) {
            this._props.dataMode = MODE_EDIT;
            const pad = this.shadow.getElementById('pad');
            pad.addEventListener('blur', this.onBlur);
            this.configure();
        } else if (this._props.dataMode === MODE_EDIT && this !== document.activeElement) {
            this.activityMonitor.validateNow();
        }
    }

    onBlur = () => {
        const pad = this.shadow.getElementById('pad');
        pad.removeEventListener('blur', this.onBlur);
        this.activityMonitor.validateNow();
    }

    onInput = evt => {
        if (this._props.dataMode === MODE_EDIT) {

            this.activityMonitor.registerActivity();

            this.resizeAndScroll();
            this.dispatchEvent(new CustomEvent('padchange', {
                detail: evt.target.value,
                bubbles: true,
            }));
            this.rawData = evt.target.value;
        }
    }

    validate() {
        const validationId = ++this.validationId;
        return new Promise((resolve, reject) => {
            const pad = this.shadow.getElementById('pad');
            const validationFunctionName = this._props.dataValidation;
            if (validationFunctionName) {
                const validationFunction = window[validationFunctionName];
                if (typeof validationFunction !== 'function') throw new Error(`Identifier "${validationFunctionName}" specified in "data-validation" is not a function.`);
                validationFunction(pad.value, JSON.stringify(this._props.dataPmcSpec)).then(error => {
                    if (validationId === this.validationId) {
                        resolve(error);
                    } else {
                        reject();
                    }
                });
            }
        });
    }

    updatePadStatus(status, error = '') {
        const padStatus = this.shadow.getElementById('pad-status');
        padStatus.className = status;
        padStatus.innerHTML = padStatusTemplate(status, error);
    }

    get value() {
        return this.rawData.trim().replace(/ +/g, ' ').replace(/ +$/mg, '');
    }

    set value(newValue) {
        this.rawData = newValue;
        this._props.dataMode = MODE_DISPLAY;
        this.configure();
    }

    set colours(newValue) {
        this._colours = newValue.trim().split(/[ \n]+/);
        this._props.dataMode = MODE_DISPLAY;
        this.configure();
    }
}
